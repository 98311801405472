/*
 * Throttle Resize-triggered Events
 * Wrap your actions in this function to throttle the frequency of firing them off, for better performance, esp. on mobile.
 * ( source: http://stackoverflow.com/questions/2854407/javascript-jquery-window-resize-how-to-fire-after-the-resize-is-completed )
*/
var waitForFinalEvent = (function () {
  var timers = {};
  return function (callback, ms, uniqueId) {
    if (!uniqueId) { uniqueId = "Don't call this twice without a uniqueId"; }
    if (timers[uniqueId]) { clearTimeout (timers[uniqueId]); }
    timers[uniqueId] = setTimeout(callback, ms);
  };
})();

/*
 * updateViewportDimensions
 * returns the current vuiewport
*/
function updateViewportDimensions() {
  var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
  return { width:x,height:y };
}

/*
 * loadGravatars
 * loadds the gravitarts on a specific viewport
*/
function loadGravatars() {
  var viewport = updateViewportDimensions();

  if (viewport.width >= 768) {
    jQuery('.comment img[data-gravatar]').each(function(){
      jQuery(this).attr('src',jQuery(this).attr('data-gravatar'));
    });
  }
}

/*
 * runs on document ready
*/
jQuery(document).ready(function($) {
  var timeToWaitForLast = 100;

  loadGravatars();

  //BACK TO TOP LINK
  $("#top-link-block a").on("click", function(e) {
    e.preventDefault();
    $('html,body').animate({scrollTop:0},'slow');
  });
  $(window).on('scroll', function(event) {
      var scrollValue = $(window).scrollTop();

      if (scrollValue > 100) {
          $('#top-link-block').removeClass('d-none').removeClass("affix-top").addClass("affix")
      } else {
          $('#top-link-block').addClass('d-none').removeClass("affix").addClass("affix-top")
      }
  });

  $('.carousel-slider').slick({
    //autoplay:true,
    dots: false,
    infinite: true,
    variableWidth: true,
    slidesToScroll: 1,
    nextArrow: '<span class="dashicons dashicons-arrow-right-alt2 slick-next"></span>',
    prevArrow: '<span class="dashicons dashicons-arrow-left-alt2 slick-prev"></span>',
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToScroll: 1
        }
      }
    ]
  });

  var doubleTapMenus = function(){
    //double tapping on iPad menus (it assumes Modernizr is being used)
    var lastTouch = '';
    var $parent, $subMenu;

    $('.mobile-nav li.menu-item-has-children > .nav-arrow').on('click', function(e){
      if(!$(this).hasClass('touched') || lastTouch != $(this).attr('href')){
        e.preventDefault();
      }
      $(this).addClass('touched');
      lastTouch = $(this).attr('href');

      //toggle sub nav
      $parent = $(this).parent();
      $subMenu = $parent.find("> .dropdown-menu");

      $subMenu.toggleClass('active');

      if (!$subMenu.hasClass("active")) {
        $subMenu.stop().slideUp();
      } else {
        $subMenu.stop().slideDown();
      }
    });
  };

  $(document).ready(function() {
    doubleTapMenus();
  });

  /* $(window).resize(function() {
    waitForFinalEvent( function() {
      viewport = updateViewportDimensions();

      if( viewport.width >= 768 ) {
        console.log('On home page and window sized to 768 width or more.');
      } else {
        console.log('Not on home page, or window sized to less than 768.');
      }
    }, timeToWaitForLast, "your-function-identifier-string");
  }); */
}); /* end of as page load scripts */
